<template>
  <div>
    <MenuSidebar />
    <MiniCartSidebar />
    <AccountSidebar />
    <CountrySidebar />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import {
  MenuSidebar,
  MiniCartSidebar,
  AccountSidebar,
  CountrySidebar,
  FiltersSidebar,
} from '.';

export default defineComponent({
  name: 'Sidebars',
  components: {
    MenuSidebar,
    MiniCartSidebar,
    AccountSidebar,
    CountrySidebar,
    FiltersSidebar,
  },
});
</script>
