<template>
  <div class="menu-main-content">
    <div class="menu-main-content__menu">
      <div
        class="menu-main-content__menu__parents"
        v-if="items && items.length > 0"
      >
        <CustomCta
          v-for="item in items"
          :key="item.uid"
          type="label"
          :has-icon="false"
          class="menu-main-content__menu__parents__parent"
          @click="$emit('itemClick', item)"
          :disabled="selectedItem !== '' && item.label === selectedItem"
        >
          <span
            :class="{
              'menu-main-content__menu__parents__parent__label':
                item.hasChildren,
              'not-selected': selectedItem && item.label !== selectedItem,
              'menu-main-content__menu__parents__parent__label contract':
                item.label && item.label.toLowerCase().includes('contract'),
              'promo-label': isPromoLabel(item.label),
              'menu-main-content__menu__links__link__label':
                !item.hasChildren &&
                item.label &&
                !item.label.toLowerCase().includes('contract'),
            }"
          >
            {{ item.label }}
          </span>
        </CustomCta>
      </div>
      <div class="menu-main-content__menu__links" v-if="!isDesktop">
        <CustomCta
          type="label"
          :has-icon="false"
          class="menu-main-content__menu__links__link"
          @click="handleWishlistClick"
        >
          <span
            class="menu-main-content__menu__links__link__label"
            :class="{ 'not-selected': selectedItem }"
          >
            {{ $t('Wishlist') }}
          </span>
        </CustomCta>
        <CustomCta
          type="label"
          :has-icon="false"
          class="menu-main-content__menu__links__link"
          @click="handleUserClick"
        >
          <span
            class="menu-main-content__menu__links__link__label"
            :class="{ 'not-selected': selectedItem }"
          >
            {{ $t('My Account') }}
          </span>
        </CustomCta>
      </div>
    </div>
    <div class="menu-main-content__country" v-if="!isDesktop">
      <CustomCta
        type="label"
        :has-icon="false"
        class="menu-main-content__country__cta"
        @click="openSidebar('country')"
      >
        <span class="menu-main-content__country__cta__label">
          {{ marketAndCurrencyLabel }}
        </span>
      </CustomCta>
    </div>
  </div>
</template>

<script>
import {
  useRouter,
  useContext,
  defineComponent,
} from '@nuxtjs/composition-api';
import { useUser } from '@gemini-vsf/composables';
import { CustomCta } from '~/components/General';
import { useUiState, useWindow, useMarkets } from '~/composables';

export default defineComponent({
  name: 'MenuMainContent',
  components: {
    CustomCta,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: String,
      default: '',
    },
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { isDesktop } = useWindow();
    const { openSidebar } = useUiState();
    const { isAuthenticated } = useUser();
    const { marketAndCurrencyLabel } = useMarkets();
    const promoLabelsArray = new Set([
      'Promotions',
      'Promozioni',
      'Promociones',
      'Promotions',
      'Sonderangebote',
      'Double Black Friday',
      'Double Cyber Monday',
    ]);
    const isPromoLabel = (menuLabel) => promoLabelsArray.has(menuLabel);

    const handleWishlistClick = async () => {
      await router.push(app.localeRoute({ name: 'wishlist' }));
    };
    const handleUserClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'my-account' }));
      } else {
        openSidebar('account');
      }
    };

    return {
      isDesktop,
      isPromoLabel,
      openSidebar,
      handleUserClick,
      handleWishlistClick,
      marketAndCurrencyLabel,
    };
  },
});
</script>

<style lang="scss" scoped>
.menu-main-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__menu {
    &__parents {
      padding-top: 3.75rem;
      padding-bottom: 1.25rem;
      &__parent {
        &:not(:first-child) {
          padding-top: 2.5rem;
        }
        &__label {
          @include desktop-h5;
          color: var(--c-black);
          &.not-selected {
            color: var(--c-dark-gray);
          }
          &.contract {
            @include desktop-h5;
          }
          &.promo-label {
            color: var(--c-red-error);
          }
        }
      }
    }
    &__links {
      padding-top: 1.25rem;
      &__link {
        &:not(:first-child) {
          padding-top: 1.25rem;
        }
        &__label {
          &.not-selected {
            color: var(--c-dark-gray);
          }
        }
      }
    }
  }
  &__country {
    position: fixed;
    bottom: var(--spacer-sm);
    &__cta {
      &__label {
        @include custom-font-13x13-400;
      }
    }
  }
}
</style>
