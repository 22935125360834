var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomSidebar',{staticClass:"filters-sidebar",attrs:{"is-sidebar-open":_vm.openFilters,"title":_vm.$t('Filter / Order by'),"name":_vm.$t('Filter / Order by'),"position":"right"},on:{"close":function($event){return _vm.$emit('closeSidebar', false)}},scopedSlots:_vm._u([{key:"content-bottom",fn:function(){return [_c('CustomCta',{staticClass:"apply-filter-cta",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('applyFilters')}}},[_vm._v(_vm._s(_vm.$t('See results')))])]},proxy:true}])},[_c('div',{staticClass:"filters-sidebar__content"},[_c('div',{staticClass:"filters-sidebar__content-active-filters"},[(
          !_vm.loading && Array.isArray(_vm.cleanedFacets) && _vm.cleanedFacets.length > 0
        )?_c('div',{staticClass:"filters"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"delete-filters",class:_vm.disableResetFilter && 'delete-filters__disabled'},[_c('span',{on:{"click":function($event){return _vm.$emit('resetFilters')}}},[_vm._v("\n          "+_vm._s(_vm.$t('Reset all'))+"\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"filters-sidebar__content-available-filters"},[_c('SfAccordion',{attrs:{"show-chevron":false,"multiple":true,"open":_vm.openAccordions}},[_c('div',{key:"sort",staticClass:"filter-list-wrapper",on:{"click":function($event){return _vm.$emit('accordionClick', {
              name: 'Order by',
              target: 'accordion',
            })}}},[_c('SfAccordionItem',{class:{
              'not-active':
                !_vm.openAccordions.includes('Order by') &&
                _vm.openAccordions.length > 0,
            },attrs:{"header":_vm.$t('Order by')},scopedSlots:_vm._u([{key:"additional-info",fn:function(ref){
            var isOpen = ref.isOpen;
return [(!isOpen && _vm.openAccordions.length === 0)?_c('ChevronBlackDown'):(isOpen)?_c('ChevronBlackUp'):_c('ChevronGrayDown')]}}])},[_vm._v(" "),_c('SfList',_vm._l((_vm.sortBy.options),function(sortByOption,sortByOptionIndex){return _c('SfListItem',{key:("sortByOption-" + sortByOptionIndex)},[_c('CustomRadio',{key:((sortByOption.label) + "-" + sortByOptionIndex),staticClass:"filters-sidebar__filter-checkbox",attrs:{"label":("" + (sortByOption.label
                    .replace('Sort: ', '')
                    .charAt(0)
                    .toUpperCase()) + (sortByOption.label
                    .replace('Sort: ', '')
                    .slice(1))),"name":sortByOption.value,"selected":_vm.sortBy.selected,"value":sortByOption.value},on:{"change":function($event){return _vm.$emit('sortByClick', {
                      label: sortByOption.label,
                      value: sortByOption.value,
                    })}}})],1)}),1)],1)],1),_vm._v(" "),_vm._l((_vm.cleanedFacets),function(facet,facetIndex){return _c('div',{key:("facet-" + facetIndex),staticClass:"filter-list-wrapper",on:{"click":function($event){_vm.$emit('accordionClick', {
              name: _vm.facetsMap.get(facet.label),
              target: 'accordion',
            })}}},[_c('SfAccordionItem',{class:{
              'not-active':
                !_vm.openAccordions.includes(_vm.facetsMap.get(facet.label)) &&
                _vm.openAccordions.length > 0,
            },attrs:{"header":_vm.facetsMap.get(facet.label)},scopedSlots:_vm._u([{key:"additional-info",fn:function(ref){
            var isOpen = ref.isOpen;
return [(!isOpen && _vm.openAccordions.length === 0)?_c('ChevronBlackDown'):(isOpen)?_c('ChevronBlackUp'):_c('ChevronGrayDown')]}}],null,true)},[_vm._v(" "),_c('SfList',_vm._l((facet.options),function(facetOption,facetOptionIndex){return _c('SfListItem',{key:("facetOption-" + facetOptionIndex)},[(facet.id !== 'sdc_colore_vetro')?_c('CustomCheckbox',{key:((facetOption.attrName) + "-" + (facetOption.selected)),staticClass:"filters-sidebar__filter-checkbox",attrs:{"label":("" + (facetOption.attrName
                    .charAt(0)
                    .toUpperCase()) + (facetOption.attrName.slice(1))),"name":"filter-checkbox","info-message":"","error-message":"","selected-value":_vm.activeFilters.includes(facetOption.attrName)},on:{"checkboxChange":function($event){return _vm.$emit('filterClick', {
                      name: facetOption.attrName,
                      target: 'filter',
                      option: {
                        facet: facet.id,
                        option: facetOption,
                      },
                    })}}}):_c('div',{staticClass:"filter-items__filter-color",on:{"click":function($event){return _vm.$emit('filterClick', {
                      name: facetOption.attrName,
                      target: 'filter',
                      option: {
                        facet: facet.id,
                        option: facetOption,
                      },
                    })}}},[(
                      facetOption.swatch_data &&
                      facetOption.swatch_data.thumbnail
                    )?_c('div',{staticClass:"filter-items__filter-color color-filter-wrapper"},[_c('div',{staticClass:"swatch-color",class:{
                        'active-color': _vm.activeFilters.includes(
                          facetOption.attrName
                        ),
                      }},[_c('nuxt-img',{attrs:{"src":facetOption.swatch_data.thumbnail.url}})],1),_vm._v(" "),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t(facetOption.attrName)))])])]):(_vm.hexCode(facetOption))?_c('div',{staticClass:"color-filter-wrapper"},[_c('div',{staticClass:"color-filter__hex",class:{
                        'active-color': _vm.activeFilters.includes(
                          facetOption.attrName
                        ),
                      },style:({ backgroundColor: _vm.hexCode(facetOption) })}),_vm._v(" "),_c('span',{staticClass:"color-filter__label"},[_vm._v(_vm._s(("" + (facetOption.attrName))))])]):_vm._e()])],1)}),1)],1)],1)})],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }