<template>
  <ValidationObserver class="reset-password" key="log-in">
    <form class="reset-password__form" @submit.prevent>
      <CustomInput
        v-model="userEmail"
        input-name="email"
        input-id="register-email"
        :input-label="$t('Your email')"
        input-placeholder=""
        :full-width="true"
        validate-rule="required|email"
      />
      <recaptcha v-if="recaptcha" />
      <div v-if="forgotPasswordError.request">
        <p class="reset-password__paragraph">
          {{
            $t(
              'It was not possible to request a new password, please check the entered email address.'
            )
          }}
        </p>
      </div>
      <div class="rest-password-button-wrapper">
        <CustomCta
          :disabled="forgotPasswordLoading"
          :is-full-width="true"
          theme="dark"
          @click="handleForgotten(userEmail)"
          >{{ $t('Reset Password') }}</CustomCta
        >
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { CustomInput, CustomCta } from '~/components/General/';
import { ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { useForgotPassword } from '@gemini-vsf/composables';

extend('email', {
  ...email,
  message: 'Invalid email',
});
extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  name: 'ForgottenForm',
  components: { CustomInput, ValidationObserver, CustomCta },
  props: {
    recaptcha: {
      type: [Object, Boolean],
      default: false,
    },
  },
  setup(props, { emit }) {
    const userEmail = ref('');
    const {
      request: forgotPasswordRequest,
      error: forgotPasswordError,
      loading: forgotPasswordLoading,
    } = useForgotPassword();

    const handleForgotten = async () => {
      if (props.recaptcha) props.recaptcha.init();

      if (props.recaptcha) {
        const recaptchaToken = await props.recaptcha.getResponse();

        await forgotPasswordRequest({ email: userEmail.value, recaptchaToken });
      } else {
        await forgotPasswordRequest({ email: userEmail.value });
      }

      if (!forgotPasswordError.value.request) {
        emit('sentForgottenPasswordRequest');
      }

      if (props.recaptcha) props.recaptcha.reset();
    };

    return {
      userEmail,
      forgotPasswordError,
      handleForgotten,
      forgotPasswordLoading,
    };
  },
});
</script>

<style lang="scss" scoped>
.reset-password {
  &__form {
    display: flex;
    flex-direction: column;
    gap: var(--space-s);
    .rest-password-button-wrapper {
      padding-top: 1.875rem;
    }
  }
  &__paragraph {
  }
}
</style>
