<template>
  <div class="seo-menu">
    <template v-if="menu && menu.items && menu.items.length > 0">
      <div v-for="item in menu.items" :key="item.uid">
        <a :href="localePath(item.link)">{{ item.label }}</a>
        <template v-if="item.children && item.children.length > 0">
          <div v-for="childrenLv1 in item.children" :key="childrenLv1.uid">
            <a :href="localePath(childrenLv1.link)">{{ childrenLv1.label }}</a>
            <div v-if="childrenLv1.children && childrenLv1.children.length > 0">
              <a
                v-for="childrenLv2 in childrenLv1.children"
                :key="childrenLv2.uid"
                :href="localePath(childrenLv2.link)"
              >
                {{ childrenLv2.label }}
              </a>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SeoMenu',
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.seo-menu {
  display: none;
}
</style>
