<template>
  <div>
    <ValidationObserver v-slot="{ invalid }" key="sign-up">
      <form class="sidebar-register-form" autocomplete="off" @submit.prevent>
        <div class="sidebar-register-form__gender">
          <CustomRadio
            :label="$t('Woman')"
            value="2"
            name="form-gender"
            v-model="form.gender"
          />
          <CustomRadio
            :label="$t('Man')"
            value="1"
            name="form-gender"
            v-model="form.gender"
          />
        </div>
        <CustomInput
          v-model="form.email"
          input-name="email"
          input-id="register-email"
          :input-label="$t('Your email')"
          input-placeholder=""
          :full-width="true"
          validate-rule="required|email"
          interaction-mode="eager"
        />
        <CustomInput
          v-model="form.firstName"
          input-name="first-name"
          input-id="first-name"
          :input-label="$t('First Name')"
          input-placeholder=""
          :full-width="true"
          validate-rule="required"
          interaction-mode="eager"
        />
        <CustomInput
          v-model="form.lastName"
          input-name="last-name"
          input-id="last-name"
          :input-label="$t('Last Name')"
          input-placeholder=""
          :full-width="true"
          validate-rule="required"
          interaction-mode="eager"
        />
        <CustomInput
          v-model="form.date_of_birth"
          input-name="date_of_birth"
          input-id="date_of_birth"
          :input-label="$t('Date of birth')"
          input-placeholder=""
          input-type="date"
          :full-width="true"
          interaction-mode="eager"
        />
        <CustomSelect
          select-name="country"
          :full-width="true"
          :select-label="$t('Country')"
          :select-options="parsedCountries"
          v-if="parsedCountries.length > 0"
          is-required
          v-model="form.country"
        />
        <CustomInput
          v-model="form.city"
          input-name="city"
          input-id="city"
          :input-label="$t('City')"
          input-placeholder=""
          :full-width="true"
          validate-rule="required"
        />

        <CustomInput
          v-model="form.phone"
          input-name="phone"
          input-id="phone"
          :input-label="$t('Phone')"
          input-placeholder=""
          :full-width="true"
        />

        <div class="password-container">
          <CustomInput
            v-model="form.password"
            input-name="password"
            input-id="password"
            input-type="password"
            :input-label="$t('Password')"
            input-placeholder=""
            :full-width="true"
            class="password-input"
            :class="!passwordsMatch && 'password-input-error'"
            validate-rule="required|min:8|customPassword"
          />
          <CustomInput
            v-model="form.repassword"
            :input-label="$t('password repeat')"
            input-name="repassword"
            input-id="repassword"
            input-type="password"
            input-placeholder=""
            :full-width="true"
            class="password-input password-input-confirm"
            :class="!passwordsMatch && 'password-input-error'"
            validate-rule="required"
          />

          <div
            v-if="!passwordsMatch && form.password.length >= 8"
            class="password-error"
          >
            {{ $t('The passwords must match') }}
          </div>
        </div>

        <CustomCheckbox
          @checkboxChange="(e) => (form.privacy = e)"
          validate-rule="required"
        />

        <CustomCheckbox
          @checkboxChange="(e) => (form.newsletter = e)"
          name="newsletter"
          :label="
            $t(
              'I agree with the handling of my data by this website in order to receive commercial or promotional communications through a newsletter'
            )
          "
        />

        <CustomCheckbox
          @checkboxChange="(e) => (form.marketing = e)"
          name="marketing"
          :label="
            $t(
              'I agree with the handling of my data by this website for profiling activities, for example the creation of personalized contents or offers.'
            )
          "
        />
        <CustomCta
          :disabled="loading || invalid"
          @click="handleRegister"
          :is-full-width="true"
          >{{ $t('Create an account') }}
        </CustomCta>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onBeforeMount,
  computed,
} from '@nuxtjs/composition-api';
import { ValidationObserver, extend } from 'vee-validate';
import { required, email, confirmed } from 'vee-validate/dist/rules';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/helpers/customer/regex';

import {
  CustomInput,
  CustomCta,
  CustomCheckbox,
  CustomRadio,
  CustomSelect,
} from '~/components/General/';
import { useUser, useCountrySearch } from '@gemini-vsf/composables';

import { useUiNotification } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';

extend('email', {
  ...email,
  message: 'Invalid email',
});
extend('required', {
  ...required,
  message: 'This field is required',
});
extend('confirmed', {
  ...confirmed,
  message: 'Please make sure your passwords match',
});
extend('password', {
  message: invalidPasswordMsg,
  validate: (value) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  name: 'RegisterForm',
  components: {
    ValidationObserver,
    CustomInput,
    CustomCta,
    CustomCheckbox,
    CustomRadio,
    CustomSelect,
  },
  props: {
    recaptcha: {
      type: [Object, Boolean],
      default: false,
    },
  },
  setup(props, { emit }) {
    const isSubscribed = ref(true);
    const error = reactive({
      register: null,
    });
    const { register, loading, error: userError } = useUser();
    const form = ref({
      gender: null,
      firstName: null,
      lastName: null,
      email: null,
      date_of_birth: null,
      country: null,
      city: null,
      password: null,
      repassword: null,
      phone: null,
      privacy: null,
      newsletter: null,
      marketing: null,
    });
    const passwordsMatch = computed(
      () => form.value.password === form.value.repassword
    );

    const resetErrorValues = () => {
      error.register = null;
    };

    const { load: loadCountries, countries } = useCountrySearch();

    const parseCountries = (countriesArray) => {
      return countriesArray
        .map((e) => {
          return {
            value: e.two_letter_abbreviation,
            label: e.full_name_locale,
          };
        })
        .sort((a, b) => {
          if (a.value === 'ZZ') return 1;
          if (b.value === 'ZZ') return -1;
          return a.label.localeCompare(b.label);
        });
    };

    const parsedCountries = ref([]);

    const { send: sendNotification } = useUiNotification();
    const trans = useI18n();

    onBeforeMount(async () => {
      await loadCountries();
      parsedCountries.value = parseCountries(countries.value);
    });

    const handleRegister = async () => {
      resetErrorValues();

      if (props.recaptcha) props.recaptcha.init();

      if (props.recaptcha) {
        const recaptchaToken = await props.recaptcha.getResponse();
        form.value.recaptchaInstance = props.recaptcha;

        await register({
          user: {
            email: form.value.email,
            firstName: form.value.firstName,
            lastName: form.value.lastName,
            password: form.value.password,
            repassword: form.value.repassword,
            is_subscribed: form.value.newsletter,
            recaptchaToken,
          },
        });
      } else {
        await register({
          user: {
            email: form.value.email,
            firstName: form.value.firstName,
            lastName: form.value.lastName,
            password: form.value.password,
            repassword: form.value.repassword,
            is_subscribed: form.value.newsletter,
            gender: form.value.gender
              ? form.value.gender === '1'
                ? 'M'
                : 'F'
              : 'O',
            city: form.value.city,
            country: form.value.country,
            phone: form.value.phone,
          },
        });
      }

      const hasUserErrors = userError.value.register;

      if (hasUserErrors) {
        error.register = userError.value.register?.message;
        sendNotification({
          id: Symbol('register_failed'),
          message: trans.t(
            'There was an error while trying to sign you up. Please try again.'
          ),
          type: 'danger',
          persist: true,
          icon: 'error',
          title: 'Registration Error',
        });
        return;
      }

      emit('loginEvent');
    };

    return {
      isSubscribed,
      register,
      error,
      loading,
      form,
      parsedCountries,
      passwordsMatch,
      handleRegister,
    };
  },
});
</script>

<style lang="scss" scoped>
.sidebar-register-form {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;

  &__gender {
    display: flex;
    justify-content: space-evenly;
  }
}
</style>

<style lang="scss">
.sidebar-register-form {
  .custom-select__container {
    width: 100%;
  }
  label[for='date_of_birth'] {
    font-size: 10px;
  }
  #date_of_birth {
    padding-top: 0;
  }
}
.password-container {
  .password-input-error {
    input {
      outline-color: var(--c-red-error);
    }
  }
  .password-error {
    padding-left: 1rem;
    margin-top: 1.5rem;
    color: var(--c-red-error);
  }
  .password-input-confirm {
    margin-top: 1.875rem;
  }
}
</style>
