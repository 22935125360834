<template>
  <div>
    <style v-if="isDesktop">
      :root {
        --sidebar-aside-width: {{ `calc(100vw / 3 * ${currentLevel + 1})` }};
      }
    </style>
    <CustomSidebar
      :is-sidebar-open="isSidebarOpen"
      class="menu-sidebar"
      name="menu"
      position="left"
    >
      <div class="menu-sidebar__panels">
        <Transition name="menu-panels">
          <div
            class="menu-sidebar__panels__panel first-panel"
            v-if="isDesktop || (!selectedParent && !selectedChildren)"
          >
            <div class="menu-sidebar__panels__panel__index" v-if="isDesktop">
              01
            </div>
            <div v-else class="menu-sidebar__panels__panel__header">
              <IconCloseBlack
                class="menu-sidebar__panels__panel__header__close"
                @click="closeSidebar('menu')"
              />
              <span />
            </div>
            <MenuMainContent
              class="menu-sidebar__panels__panel__content"
              :items="splittedMenu.main"
              :selected-item="selectedParent"
              @itemClick="(item) => handleItemClick(item, 0)"
            />
          </div>
        </Transition>
        <Transition name="menu-panels">
          <div
            class="menu-sidebar__panels__panel second-panel"
            :class="{ 'not-visible': isDesktop && currentLevel < 1 }"
            v-if="isDesktop || (selectedParent && !selectedChildren)"
          >
            <div class="menu-sidebar__panels__panel__index" v-if="isDesktop">
              02
            </div>
            <div v-else class="menu-sidebar__panels__panel__header">
              <IconCloseBlack
                class="menu-sidebar__panels__panel__header__close"
                @click="closeSidebar('menu')"
              />
              <GoBackButton
                class="menu-sidebar__panels__panel__header__go-back"
                :router-go-back="false"
                @click="handleItemClick('', 0)"
              />
            </div>
            <Transition name="menu-children-content">
              <MenuChildrenContent
                :key="selectedParent"
                class="menu-sidebar__panels__panel__content"
                :currentMenuUid="navigationMenu.uid"
                :items="splittedMenu.childrenLv1[selectedParent]"
                :selected-item="selectedChildren"
                @itemClick="(item) => handleItemClick(item, 1)"
              />
            </Transition>
          </div>
        </Transition>
        <Transition name="menu-panels">
          <div
            class="menu-sidebar__panels__panel third-panel"
            :class="{ 'not-visible': isDesktop && currentLevel < 2 }"
            v-if="isDesktop || (selectedParent && selectedChildren)"
          >
            <div class="menu-sidebar__panels__panel__index" v-if="isDesktop">
              03
            </div>
            <div v-else class="menu-sidebar__panels__panel__header">
              <IconCloseBlack
                class="menu-sidebar__panels__panel__header__close"
                @click="closeSidebar('menu')"
              />
              <GoBackButton
                class="menu-sidebar__panels__panel__header__go-back"
                :router-go-back="false"
                @click="handleItemClick('', 1)"
              />
            </div>
            <Transition name="menu-children-content">
              <MenuChildrenContent
                :key="selectedChildren"
                :currentMenuUid="navigationMenu.uid"
                class="menu-sidebar__panels__panel__content"
                :items="splittedMenu.childrenLv2[selectedChildren]"
                @itemClick="(item) => handleItemClick(item, 2)"
              />
            </Transition>
          </div>
        </Transition>
      </div>
    </CustomSidebar>
    <SeoMenu v-if="navigationMenu" :menu="navigationMenu" />
  </div>
</template>

<script>
import {
  ref,
  watch,
  useFetch,
  computed,
  useRouter,
  useContext,
  defineComponent,
} from '@nuxtjs/composition-api';
import { CustomSidebar } from '~/components/General';
import { useMenu } from '@gemini-vsf/composables';
import { useWindow, useUiState } from '~/composables';
import { GoBackButton } from '~/components/Header';
import { IconCloseBlack } from '~/components/General/Icons';
import { MenuMainContent, MenuChildrenContent, SeoMenu } from '.';

export default defineComponent({
  name: 'MenuSidebar',
  components: {
    IconCloseBlack,
    SeoMenu,
    GoBackButton,
    CustomSidebar,
    MenuMainContent,
    MenuChildrenContent,
  },
  setup() {
    const { app } = useContext();
    const { isDesktop } = useWindow();

    // --- handle menu data ---

    const { menu, search: searchMenu } = useMenu('mainmenu');
    const navigationMenu = ref({});
    useFetch(async () => {
      try {
        await searchMenu({ code: 'mainmenu' });
        navigationMenu.value = menu.value;
      } catch {
        console.warning('Could not get mainmenu menu content');
      }
    });

    const hasChildren = (menuItem) => Boolean(menuItem?.children?.length > 0);
    const splittedMenu = computed(() => {
      const splittedMenuData = {
        main: [],
        childrenLv1: [],
        childrenLv2: [],
      };
      navigationMenu.value?.items?.forEach((mainItem) => {
        splittedMenuData.main.push({
          uid: mainItem.uid,
          label: mainItem.label,
          link: mainItem.link,
          hasChildren: hasChildren(mainItem),
        });
        if (hasChildren(mainItem)) {
          splittedMenuData.childrenLv1[mainItem.label] = [];
          mainItem.children.forEach((childrenLv1Item) => {
            splittedMenuData.childrenLv1[mainItem.label].push({
              uid: childrenLv1Item.uid,
              label: childrenLv1Item.label,
              link: childrenLv1Item.link,
              hasChildren: hasChildren(childrenLv1Item),
            });
            if (hasChildren(childrenLv1Item)) {
              splittedMenuData.childrenLv2[childrenLv1Item.label] = [];
              childrenLv1Item.children.forEach((childrenLv2Item) => {
                splittedMenuData.childrenLv2[childrenLv1Item.label].push({
                  uid: childrenLv2Item.uid,
                  label: childrenLv2Item.label,
                  link: childrenLv2Item.link,
                });
              });
            }
          });
        }
      });
      return splittedMenuData;
    });

    // --- handle menu panels/items ---

    const { openedSidebar, sidebarData, closeSidebar, updateSidebarData } =
      useUiState();
    const router = useRouter();
    const selectedParent = computed(() => sidebarData.value.selectedParent);
    const selectedChildren = computed(() => sidebarData.value.selectedChildren);
    const currentLevel = computed(() =>
      selectedParent.value ? (selectedChildren.value ? 2 : 1) : 0
    );
    const handleItemClick = (item, lv) => {
      if (!item) {
        if (lv) {
          updateSidebarData('menu', {
            selectedParent: selectedParent.value,
          });
        } else {
          updateSidebarData('menu', {});
        }
      } else if (item.hasChildren) {
        if (lv) {
          updateSidebarData('menu', {
            selectedParent: selectedParent.value,
            selectedChildren: item.label,
          });
        } else {
          updateSidebarData('menu', { selectedParent: item.label });
        }
      } else {
        router.push(app.localePath(item.link));
      }
    };
    const isSidebarOpen = ref(false);
    watch(openedSidebar, (newValue) => {
      isSidebarOpen.value = newValue === 'menu';
    });

    return {
      menu,
      isDesktop,
      navigationMenu,
      splittedMenu,
      currentLevel,
      selectedParent,
      selectedChildren,
      handleItemClick,
      closeSidebar,
      isSidebarOpen,
    };
  },
});
</script>

<style lang="scss" scoped>
.menu-sidebar {
  &__panels {
    width: 100vw;
    @include from-desktop-min {
      min-height: 100vh;
      overflow: hidden;
    }
    display: flex;
    &__panel {
      width: calc(100vw / 3 - 2.5rem);
      height: 100%;
      @include to-tablet-max {
        height: unset;
        min-height: 100vh;
        &.first-panel {
          overflow: hidden;

          .menu-sidebar__panels__panel__content {
            overflow: hidden;
          }
        }
      }
      @include from-desktop-min {
        &.second-panel,
        &.third-panel {
          overflow-y: auto;
          overflow-x: hidden;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      padding: 1.875rem 1.25rem 1.25rem;
      transition: opacity 0.5s;
      background-color: var(--c-white);
      &:not(:first-child) {
        border-left: var(--general-border);
      }
      &__index {
        text-align: end;
      }
      &__content {
        width: calc(100vw / 3 - 2.5rem);
      }
      &.not-visible {
        opacity: 0;
      }
    }
  }
  @include to-tablet-max {
    &__panels {
      display: block;
      &__panel {
        width: calc(100vw - 2.5rem);
        padding: 0 1.25rem;
        &__header {
          display: flex;
          justify-content: space-between;
          padding-top: 1.25rem;
        }
        &__content {
          width: calc(100vw - 2.5rem);
        }
        &:not(:first-child) {
          border: none;
        }
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
@include from-desktop-min {
  .menu-children-content-enter-from,
  .menu-children-content-leave-to {
    opacity: 0 !important;
  }
  .menu-children-content-enter-to,
  .menu-children-content-leave-from {
    opacity: 1 !important;
  }
  .menu-children-content-enter-active,
  .menu-children-content-leave-active {
    position: absolute;
    transition: opacity 0.5s ease;
    &:nth-child(3) {
      opacity: 0;
    }
  }
}

@include to-tablet-max {
  .menu-panels-enter-from {
    transform: translateX(-100%) !important;
  }
  .menu-panels-enter-to,
  .menu-panels-leave-from {
    transform: translateX(0%) !important;
  }
  .menu-panels-enter-active,
  .menu-panels-leave-active {
    transition: all 0.5s ease;
    position: absolute;
    &:nth-child(2) {
      transform: translateX(-100%);
    }
  }
}
</style>

<style lang="scss">
.menu-sidebar.custom-sidebar.sf-sidebar {
  .sf-sidebar {
    &__aside {
      width: var(--sidebar-aside-width);
      max-width: unset;
      min-width: 0;
      background-color: transparent;
      overflow-x: hidden;
    }
    &__top {
      z-index: 1;
      position: absolute;
    }
    &__content {
      @include from-desktop-min {
        position: absolute;
      }
      padding: 0;
    }
  }
  @include to-tablet-max {
    .sf-sidebar {
      &__top {
        display: none;
      }
    }
    .sf-overlay {
      background: var(--c-white);
    }
  }
}
</style>
