var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-main-content"},[_c('div',{staticClass:"menu-main-content__menu"},[(_vm.items && _vm.items.length > 0)?_c('div',{staticClass:"menu-main-content__menu__parents"},_vm._l((_vm.items),function(item){return _c('CustomCta',{key:item.uid,staticClass:"menu-main-content__menu__parents__parent",attrs:{"type":"label","has-icon":false,"disabled":_vm.selectedItem !== '' && item.label === _vm.selectedItem},on:{"click":function($event){return _vm.$emit('itemClick', item)}}},[_c('span',{class:{
            'menu-main-content__menu__parents__parent__label':
              item.hasChildren,
            'not-selected': _vm.selectedItem && item.label !== _vm.selectedItem,
            'menu-main-content__menu__parents__parent__label contract':
              item.label && item.label.toLowerCase().includes('contract'),
            'promo-label': _vm.isPromoLabel(item.label),
            'menu-main-content__menu__links__link__label':
              !item.hasChildren &&
              item.label &&
              !item.label.toLowerCase().includes('contract'),
          }},[_vm._v("\n          "+_vm._s(item.label)+"\n        ")])])}),1):_vm._e(),_vm._v(" "),(!_vm.isDesktop)?_c('div',{staticClass:"menu-main-content__menu__links"},[_c('CustomCta',{staticClass:"menu-main-content__menu__links__link",attrs:{"type":"label","has-icon":false},on:{"click":_vm.handleWishlistClick}},[_c('span',{staticClass:"menu-main-content__menu__links__link__label",class:{ 'not-selected': _vm.selectedItem }},[_vm._v("\n          "+_vm._s(_vm.$t('Wishlist'))+"\n        ")])]),_vm._v(" "),_c('CustomCta',{staticClass:"menu-main-content__menu__links__link",attrs:{"type":"label","has-icon":false},on:{"click":_vm.handleUserClick}},[_c('span',{staticClass:"menu-main-content__menu__links__link__label",class:{ 'not-selected': _vm.selectedItem }},[_vm._v("\n          "+_vm._s(_vm.$t('My Account'))+"\n        ")])])],1):_vm._e()]),_vm._v(" "),(!_vm.isDesktop)?_c('div',{staticClass:"menu-main-content__country"},[_c('CustomCta',{staticClass:"menu-main-content__country__cta",attrs:{"type":"label","has-icon":false},on:{"click":function($event){return _vm.openSidebar('country')}}},[_c('span',{staticClass:"menu-main-content__country__cta__label"},[_vm._v("\n        "+_vm._s(_vm.marketAndCurrencyLabel)+"\n      ")])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }