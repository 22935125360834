<template>
  <div class="menu-children-content" v-if="items.length > 0">
    <div
      class="menu-children-content__item"
      :class="{ disabled: selectedItem !== '' && item.label === selectedItem }"
      v-for="item in items"
      :key="item.uid"
      @click="$emit('itemClick', item)"
    >
      <div
        class="menu-children-content__item__label"
        :class="{ 'not-selected': selectedItem && item.label !== selectedItem }"
      >
        <IconArrowBlack class="menu-children-content__item__label__icon" />
        <span>{{ item.label }}</span>
      </div>
      <div class="menu-children-content__item__images">
        <NuxtImgCustom
          v-for="index in 3"
          placeholder="/logo/logo-black.svg"
          :src="`/menu-images/${currentMenuUid}/${item.uid}/${index}.jpeg`"
          width="61"
          height="61"
          class="menu-children-content__item__images__image"
          :key="`menu-images-${currentMenuUid}-${item.uid}-${index}`"
          hide-on-error
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { IconArrowBlack } from '~/components/General/Icons';
import NuxtImgCustom from '~/components/General/NuxtImgCustom/NuxtImgCustom.vue';

export default defineComponent({
  name: 'MenuChildrenContent',
  components: {
    IconArrowBlack,
    NuxtImgCustom,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: String,
      default: '',
    },
    currentMenuUid: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.menu-children-content {
  padding-top: 3.625rem;
  &__item {
    border-top: var(--general-border);
    height: 4.8125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 3s;
    cursor: pointer;
    overflow: hidden;
    &__label {
      display: flex;
      align-items: center;
      color: var(--c-black);
      transform: translateX(-1.375rem);
      &.not-selected {
        color: var(--c-dark-gray);
      }
      &__icon {
        min-width: 1.375rem;
      }
    }
    &__images {
      display: flex;
      transform: translateX(8.125rem);
      &__image {
        &:not(:first-child) {
          padding-left: 0.25rem;
        }
      }
    }
    &:last-child {
      padding-bottom: 1.25rem;
    }
  }
}
@include from-desktop-min {
  .menu-children-content {
    &__item {
      &__label {
        transition: all 0.4s ease-in-out;
      }
      &__images {
        transition: all 0.4s ease-in-out;
      }
      &:hover {
        .menu-children-content__item {
          &__label {
            transform: translateX(0);
          }
          &__images {
            transform: translateX(0);
          }
        }
      }
    }
  }
}
</style>
