<template>
  <CustomSidebar
    :is-sidebar-open="isSidebarOpen"
    class="mini-cart-sidebar"
    name="minicart"
    position="right"
    :title="cartTotalItems ? `Your Cart (${cartTotalItems})` : ''"
  >
    <section v-if="cartTotalItems" class="mini-cart-sidebar__items">
      <CartItem
        v-for="(cartItem, index) in cartItems"
        :key="`cart-item-${cartItem.uid || ''}-${index}`"
        :cart-item="cartItem"
      />
    </section>
    <section v-else class="mini-cart-sidebar__empty">
      <div class="mini-cart-sidebar__empty__text">
        <div class="mini-cart-sidebar__empty__text__title">
          {{ $t('Your cart is empty') }}
        </div>
        <div class="mini-cart-sidebar__empty__text__subtitle">
          {{ $t("It looks like you haven't added any items to your cart yet") }}
        </div>
      </div>
      <CustomCta
        class="mini-cart-sidebar__empty__cta"
        theme="dark"
        @click="handleClose"
      >
        {{ $t('SHOP NOW') }}
      </CustomCta>
    </section>
    <CartSummary v-if="cartTotalItems" :cart="cart" />
  </CustomSidebar>
</template>

<script>
import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { useUiState, useCart } from '~/composables';
import { CustomSidebar } from '~/components/General';
import CartItem from '~/components/Cart/CartItem.vue';
import CartSummary from '~/components/Cart/CartSummary.vue';
import { CustomCta } from '~/components/General/';

export default defineComponent({
  name: 'MiniCartSidebar',
  components: {
    CustomSidebar,
    CartItem,
    CartSummary,
    CustomCta,
  },
  setup(props, { emit }) {
    const { openedSidebar, closeSidebar } = useUiState();
    const isSidebarOpen = ref(false);
    const { cart, cartTotalItems, cartItems } = useCart();

    watch(openedSidebar, (newValue) => {
      isSidebarOpen.value = newValue === 'minicart';
    });

    const handleClose = () => {
      closeSidebar(props.name);
      emit('close');
    };

    return { isSidebarOpen, cart, cartTotalItems, cartItems, handleClose };
  },
});
</script>

<style lang="scss">
.mini-cart-sidebar {
  position: relative;
  &__items {
    height: calc(100vh - 15.375rem);
    overflow-y: scroll;
  }
  .sf-sidebar__content {
    padding-bottom: 0 !important;
  }
  &:has(&__empty) {
    .sf-sidebar__content {
      padding-top: 0;
    }
  }
  &__empty {
    height: 100%;
    width: 100%;
    @include flex-center;
    &__text {
      text-align: center;
      &__title {
        @include desktop-h6;
        padding-bottom: 0.875rem;
      }
      &__subtitle {
        @include paragraph-xl;
      }
    }
    &__cta {
      position: fixed;
      bottom: 1.875rem;
      padding: 0 1.25rem;
      width: calc(100% - 2.5rem);
      text-decoration: none;
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      width: -webkit-fill-available !important;
      max-width: -webkit-fill-available !important;
    }
  }
}
</style>
