<template>
  <div class="sf-sidebar__bottom__container__login">
    <CustomCta
      :type="!isSelected ? 'label' : 'button'"
      :is-full-width="true"
      @click="$emit('showLogin')"
      >{{ $t('Login') }}</CustomCta
    >
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { CustomCta } from '~/components/General/';

export default defineComponent({
  name: 'ShowLogin',
  components: { CustomCta },
  props: {
    isSelected: {
      type: [Object, Boolean],
      default: false,
    },
  },
  setup() {},
});
</script>

<style lang="scss">
.sf-sidebar__bottom__container__login {
  text-align: center;
  align-items: center;
  display: flex;
  width: 30%;
  .text {
    margin: 0 0 1.563rem;
  }
  .custom-label {
    min-width: 7.5rem;
    width: 100%;
    text-transform: uppercase;
    padding: 0.625rem 0.625rem 0.625rem 0;
    margin-left: 1.25rem;
  }
}
</style>
