<template>
  <div class="login-sidebar__login-form">
    <div class="mandatory-label-wrapper">
      <span class="mandatory-label-wrapper__label">{{
        $t('*mandatory field')
      }}</span>
    </div>
    <ValidationObserver key="log-in" v-slot="{ valid }">
      <form class="login-sidebar__login-form__form" @submit.prevent>
        <CustomInput
          v-model="form.email"
          input-name="email"
          input-id="register-email"
          :input-label="$t('Your Email*')"
          input-placeholder=""
          :full-width="true"
          validate-rule="required|email"
          @keydown.enter="handleLogin"
        />
        <div class="password-input-container">
          <CustomInput
            v-model="form.password"
            input-name="password"
            input-id="login-form-password"
            :input-label="$t('Password*')"
            class="login-sidebar__login-form__input"
            input-placeholder=""
            :full-width="true"
            validate-rule="required"
            input-type="password"
            @keydown.enter="handleLogin"
          />
          <recaptcha v-if="recaptcha" />
          <div class="login-sidebar__login-form__forgotten">
            <span
              class="login-sidebar__login-form__forgotten__button"
              @click="$emit('showForgottenPassword')"
              >{{ $t('Forgotten password?') }}
            </span>
          </div>
        </div>
        <div class="submit-botton-wrapper">
          <CustomCta
            :is-full-width="true"
            :disabled="loading || !valid"
            @click="handleLogin"
            theme="dark"
            >{{ $t('Login') }}
          </CustomCta>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from '@nuxtjs/composition-api';
import { CustomInput, CustomCta } from '~/components/General/';
import { ValidationObserver, extend } from 'vee-validate';
import {
  required,
  email as emailRule,
  confirmed,
} from 'vee-validate/dist/rules';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/helpers/customer/regex';
import { useUser } from '@gemini-vsf/composables';
import { useUiNotification } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';

extend('email', {
  ...emailRule,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('confirmed', {
  ...confirmed,
  message: 'Please make sure your passwords match',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  name: 'LoginForm',
  components: {
    CustomCta,
    CustomInput,
    ValidationObserver,
  },
  props: {
    recaptcha: {
      type: [Object, Boolean],
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      login,
      loading,
      error: userError,
      logout,
      isAuthenticated,
      user,
    } = useUser();
    const error = reactive({
      login: null,
    });

    const form = ref({});

    const resetErrorValues = () => {
      error.login = null;
    };

    const { send: sendNotification } = useUiNotification();
    const trans = useI18n();

    const handleLogin = async () => {
      resetErrorValues();

      if (props.recaptcha) props.recaptcha.init();

      if (props.recaptcha) {
        const recaptchaToken = await props.recaptcha.getResponse();
        form.value.recaptchaInstance = props.recaptcha;

        await login({
          user: {
            ...form.value,
            recaptchaToken,
          },
        });
      } else {
        await login({
          user: {
            username: form.value.email,
            password: form.value.password,
          },
        });
      }

      const hasUserErrors = userError.value.login;
      if (hasUserErrors || !user.value) {
        if (isAuthenticated.value) {
          await logout();
        }
        sendNotification({
          id: Symbol('login_failed'),
          message: trans.t(
            'There was an error while trying to login. Are your credentials correct?'
          ),
          type: 'danger',
          persist: false,
          icon: 'error',
          title: 'Login Error',
        });
        if (hasUserErrors) {
          error.login = userError.value.login?.message;
        }
        return;
      }

      emit('loginEvent');
    };

    return {
      error,
      form,
      loading,
      handleLogin,
    };
  },
});
</script>

<style lang="scss" scoped>
.login-sidebar__login-form {
  .mandatory-label-wrapper {
    text-align: end;
    &__label {
      @include label-s;
      color: #06162e;
      text-transform: uppercase;
      margin: 0 auto;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    .password-input-container {
      width: 100%;
      .custom-input__input {
        width: 100%;
      }
    }
  }
  &__forgotten {
    padding-top: 0.625rem;
    text-align: end;
    &__button {
      @include paragraph-s;
      text-decoration: underline;
      cursor: pointer;
      margin: 0 auto;
      color: #000000;
    }
  }
}
</style>
