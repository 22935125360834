<template>
  <CustomSidebar
    :is-sidebar-open="isSidebarOpen"
    class="country-sidebar"
    name="country"
    :position="isDesktop ? 'right' : 'left'"
    :has-close-icon="false"
    :persistent="true"
  >
    <div class="country-sidebar__content">
      <div class="country-sidebar__content__switcher">
        <div class="country-sidebar__content__switcher__title">
          {{
            currentCountry
              ? `${$t('You are currently Shopping in')} ${currentCountry}`
              : $t('Select country and language')
          }}
        </div>
        <CustomSelect
          :is-disabled="countriesArray.length === 0"
          class="country-sidebar__content__switcher__select"
          select-name="countries"
          v-model="countryData.country"
          :select-label="$t('Where do you want to purchase?')"
          :is-required="true"
          :select-options="countriesArray"
          :full-width="true"
          select-icon="IconMoreBlack1616"
          @selectChange="(c) => changeCountry(c)"
        />
        <CustomSelect
          :is-disabled="!countryData.country"
          class="country-sidebar__content__switcher__select"
          select-name="languages"
          v-model="countryData.language"
          :select-label="$t('Language')"
          :is-required="true"
          :select-options="languagesArray"
          :full-width="true"
          select-icon="IconMoreBlack1616"
          @selectChange="(c) => updateLocalStorage('language', c)"
          :key="languageKey"
        />
      </div>
      <div
        class="country-sidebar__content__apply--wrapper"
        :class="
          (!countryData.country || !countryData.language || !selectedLocale) &&
          'country-sidebar__content__apply--wrapper--disabled'
        "
      >
        <a class="country-sidebar__content__apply" :href="selectedLocale">
          <span class="country-sidebar__content__apply--text">
            {{ $t('APPLY SETTINGS') }}
          </span>
          <span class="country-sidebar__content__apply--icon">
            <component
              :is="
                !countryData.country || !countryData.language
                  ? 'IconArrowBlack'
                  : 'IconArrowWhite'
              "
            />
          </span>
        </a>
      </div>
    </div>
  </CustomSidebar>
</template>

<script>
import {
  ref,
  defineComponent,
  computed,
  useFetch,
  onMounted,
  watch,
  useRoute,
  useContext,
} from '@nuxtjs/composition-api';
import { CustomSelect, CustomSidebar } from '~/components/General';
import { useWindow, useMarkets, useUiState } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { IconArrowWhite, IconArrowBlack } from '~/components/General/Icons';

export default defineComponent({
  name: 'CountrySidebar',
  components: {
    CustomSelect,
    CustomSidebar,
    IconArrowWhite,
    IconArrowBlack,
  },
  setup() {
    const localStorageItemName = 'vsf-country-selected';

    const trans = useI18n();
    const { isDesktop } = useWindow();
    const {
      languageMap,
      getAllAvailableCountriesWithFullName,
      availableCountriesWithFullName,
      getAvailableLanguagesForCountry,
      findMarketForCountryIso,
    } = useMarkets();
    const route = useRoute();
    const {
      app: { $i2c: countryIsoToName },
    } = useContext();

    const countriesArray = ref([]);
    const languagesArray = ref([]);
    const countryData = ref({
      country: '',
      language: '',
    });
    const currentCountry = ref();
    const languageKey = ref(1);
    const validLocale = ref();

    const selectedLocale = computed(() =>
      countryData.value.country?.length > 0 &&
      countryData.value.language?.length > 0
        ? `/${findMarketForCountryIso(
            countryData.value.country,
            countryData.value.language
          )}`
        : null
    );

    const { openedSidebar, openSidebar, openedModal } = useUiState();
    const isSidebarOpen = ref(false);
    watch(openedSidebar, (newValue) => {
      isSidebarOpen.value = newValue === 'country';
    });

    const updateLocalStorage = (key, value) => {
      countryData.value[key] = value;
      localStorage.setItem(
        localStorageItemName,
        JSON.stringify(countryData.value)
      );
    };
    const updateLanguagesForCountry = async (c) => {
      languagesArray.value = [];
      languagesArray.value = await getAvailableLanguagesForCountry(c);
      languagesArray.value = languagesArray.value.map((l) => ({
        value: l,
        label: trans.t(languageMap.get(l)),
      }));
      languagesArray.value.sort((a, b) => a.label.localeCompare(b.label));
      countryData.value.language = languagesArray.value[0].value;
      languageKey.value += 1;
    };
    const changeCountry = async (c) => {
      countryData.value.language = '';
      await updateLanguagesForCountry(c);
      updateLocalStorage('country', c);
    };
    const checkCurrentLocale = () => {
      const localeCode = route?.value?.fullPath?.split('/')?.[1];
      currentCountry.value = `${
        localeCode?.split('-')?.[0]?.toUpperCase() || ''
      }`;
      validLocale.value = trans.locales.find(
        (locale) => locale.code === localeCode
      );
      if (validLocale.value) {
        trans.setLocale(localeCode);
      }
    };

    const sortMainCountries = (countries) => {
      let sortedCountries = countries;
      if (sortedCountries.length > 0) {
        const mainCountries = [
          'IT',
          'US',
          'GB',
          'FR',
          'DE',
          'CH',
          'AT',
          'ES',
          'CA',
        ];
        const matchedCountries = {};
        sortedCountries = countries.filter((country) => {
          if (mainCountries.includes(country.value)) {
            matchedCountries[country.value] = country;
            return false;
          }
          return true;
        });
        if (matchedCountries !== {}) {
          [...mainCountries].reverse().forEach((code) => {
            if (code in matchedCountries) {
              sortedCountries.unshift(matchedCountries[code]);
            }
          });
        }
      }
      return sortedCountries;
    };

    useFetch(async () => {
      await getAllAvailableCountriesWithFullName();
      countriesArray.value = availableCountriesWithFullName.value;
      countriesArray.value.sort((a, b) => a.label.localeCompare(b.label));
      countriesArray.value = sortMainCountries(countriesArray.value);
    });

    onMounted(async () => {
      checkCurrentLocale();
      if (!openedModal === 'geolocator') {
        if (
          localStorage.getItem(localStorageItemName) ||
          localStorage.getItem('vsf-localized-country') === null
        ) {
          countryData.value = JSON.parse(
            localStorage.getItem(localStorageItemName)
          );
          if (countryData.value.country) {
            if (
              validLocale.value?.countries.includes(countryData.value.country)
            ) {
              currentCountry.value = countryIsoToName(
                countryData.value.country
              );
            }
            await updateLanguagesForCountry(countryData.value.country);
          }
          if (!countryData.value.country || !countryData.value.language) {
            openSidebar('country');
          }
        } else {
          openSidebar('country');
        }
      }
    });

    return {
      countryData,
      currentCountry,
      isDesktop,
      countriesArray,
      languagesArray,
      changeCountry,
      updateLocalStorage,
      selectedLocale,
      isSidebarOpen,
      languageKey,
    };
  },
});
</script>

<style lang="scss" scoped>
.country-sidebar {
  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__switcher {
      &__title {
        padding: 3.125rem 0 1.25rem;
        @include paragraph-xl;
      }
      &__select {
        margin-top: 0.625rem;
      }
    }
    &__apply {
      width: 100%;
      border-radius: 100px;
      background-color: var(--c-black);
      color: var(--c-white);
      height: 2.8125rem;
      @include flex-center;
      @include label-l;
      @include pointer;
      &--icon {
        @include flex-center;
      }
      &--wrapper {
        &--disabled {
          cursor: not-allowed;
          a {
            background-color: var(--c-light-gray);
            color: var(--c-dark-gray);
            path {
              stroke: var(--c-dark-gray);
            }
            pointer-events: none;
          }
        }
      }
    }
  }
  @include to-tablet-max {
    &__content {
      &__switcher {
        &__title {
          padding: 1.25rem 0 2.5rem;
        }
        &__select {
          margin-top: 1.25rem;
        }
      }
    }
  }
}
</style>
