<template>
  <CustomSidebar
    :is-sidebar-open="openedSidebar === 'account'"
    class="account-sidebar"
    name="account"
    position="right"
    @close="closeModal"
  >
    <template #content-top-title>
      <div class="account-sidebar__title">Account</div>
    </template>
    <div class="account-sidebar__actions">
      <ShowLogin @showLogin="setIsLoginValue(true)" :is-selected="isLogin" />
      <ShowRegister
        @showRegister="
          () => {
            setIsForgottenValue(false);
            setIsLoginValue(false);
          }
        "
        :is-selected="!isLogin"
      />
    </div>
    <div v-if="isLogin">
      <LoginForm
        :recaptcha="isRecaptchaEnabled && recaptchaInstance"
        @loginEvent="handleLoginEvent"
        @showForgottenPassword="setIsForgottenValue(true)"
      />
    </div>
    <div
      v-else-if="isForgotten"
      :recaptcha="isRecaptchaEnabled && recaptchaInstance"
    >
      <ForgottenForm @sentForgottenPasswordRequest="handleForgotten" />
    </div>
    <div v-else-if="isThankYouAfterForgotten" class="thank-you">
      <ForgottenThanks :user-email="userEmail" />
    </div>
    <div v-else>
      <RegisterForm
        :recaptcha="isRecaptchaEnabled && recaptchaInstance"
        @loginEvent="handleLoginEvent"
      />
    </div>
  </CustomSidebar>
</template>

<script>
import {
  defineComponent,
  ref,
  watch,
  useRoute,
  computed,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import { CustomSidebar } from '~/components/General';
import { useUser } from '@gemini-vsf/composables';
import { useUiState } from '~/composables';
import { removeItem } from '~/helpers/asyncLocalStorage';
import LoginForm from '~/components/Sidebars/Account/LoginForm.vue';
import ForgottenForm from '~/components/Sidebars/Account/ForgottenForm.vue';
import ForgottenThanks from '~/components/Sidebars/Account/ForgottenThanks.vue';
import RegisterForm from '~/components/Sidebars/Account/RegisterForm.vue';
import ShowRegister from '~/components/Sidebars/Account/ShowRegister.vue';
import ShowLogin from '~/components/Sidebars/Account/ShowLogin.vue';

export default defineComponent({
  name: 'AccountSidebar',
  components: {
    CustomSidebar,
    LoginForm,
    ForgottenForm,
    ForgottenThanks,
    RegisterForm,
    ShowRegister,
    ShowLogin,
  },
  setup() {
    const { openedSidebar, closeSidebar } = useUiState();
    const isSubscribed = ref(true);
    const isLogin = ref(true);
    const isForgotten = ref(false);
    const isThankYouAfterForgotten = ref(false);

    const userEmail = ref('');

    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(
      $recaptcha !== undefined && $config.isRecaptcha
    );

    const { app } = useContext();
    const router = useRouter();
    const { user } = useUser();
    const isLoggedIn = ref(false);
    const route = useRoute();

    watch(openedSidebar, (newValue) => {
      if (isSubscribed.value === false && newValue === 'account') {
        isSubscribed.value = true;
      }
    });

    const setIsLoginValue = (value) => {
      isLogin.value = value;
    };

    const setIsForgottenValue = (value) => {
      isForgotten.value = value;
      isLogin.value = !value;
    };

    const closeModal = () => {
      setIsForgottenValue(false);
      setIsLoginValue(true);
      closeSidebar('account');
    };

    const hasLoggedIn = computed(() => {
      if (isLoggedIn && isLoggedIn.value) {
        return isLoggedIn.value;
      }
      return false;
    });

    watch(isLoggedIn, () => {
      if (isLoggedIn.value) {
        hasLoggedIn.value = true;
        return hasLoggedIn.value;
      }
      return false;
    });

    const handleLoginEvent = async () => {
      isLoggedIn.value = true;
      setIsLoginValue(true);
      closeSidebar('account');
      await removeItem('checkout');

      if (isRecaptchaEnabled.value) $recaptcha.reset();

      if (route.value.fullPath.includes('checkout')) {
        router.push(app.localePath('/checkout/shipping'));
      } else {
        router.push(app.localePath('/my-account'));
      }
    };

    const handleForgotten = (email) => {
      isThankYouAfterForgotten.value = true;
      isForgotten.value = false;
      userEmail.value = email;
    };

    return {
      closeModal,
      handleForgotten,
      isForgotten,
      isLogin,
      isSubscribed,
      isThankYouAfterForgotten,
      setIsForgottenValue,
      setIsLoginValue,
      userEmail,
      hasLoggedIn,
      isLoggedIn,
      isRecaptchaEnabled,
      user,
      handleLoginEvent,
      openedSidebar,
      recaptchaInstance: $recaptcha,
    };
  },
});
</script>

<style lang="scss">
.account-sidebar {
  &__title {
    @include paragraph-xl;
  }
  &__actions {
    margin-top: 5rem;
    margin-bottom: 2.5rem;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 0.625rem;
  }
}
</style>
