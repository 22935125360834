<template>
  <CustomSidebar
    :is-sidebar-open="openFilters"
    :title="$t('Filter / Order by')"
    :name="$t('Filter / Order by')"
    position="right"
    class="filters-sidebar"
    @close="$emit('closeSidebar', false)"
  >
    <div class="filters-sidebar__content">
      <div class="filters-sidebar__content-active-filters">
        <div
          class="filters"
          v-if="
            !loading && Array.isArray(cleanedFacets) && cleanedFacets.length > 0
          "
        ></div>
        <div
          class="delete-filters"
          :class="disableResetFilter && 'delete-filters__disabled'"
        >
          <span @click="$emit('resetFilters')">
            {{ $t('Reset all') }}
          </span>
        </div>
      </div>
      <div class="filters-sidebar__content-available-filters">
        <SfAccordion
          :show-chevron="false"
          :multiple="true"
          :open="openAccordions"
        >
          <div
            :key="`sort`"
            class="filter-list-wrapper"
            @click="
              $emit('accordionClick', {
                name: 'Order by',
                target: 'accordion',
              })
            "
          >
            <SfAccordionItem
              :header="$t('Order by')"
              :class="{
                'not-active':
                  !openAccordions.includes('Order by') &&
                  openAccordions.length > 0,
              }"
            >
              <template #additional-info="{ isOpen }">
                <ChevronBlackDown
                  v-if="!isOpen && openAccordions.length === 0"
                />
                <ChevronBlackUp v-else-if="isOpen" />
                <ChevronGrayDown v-else />
              </template>
              <SfList>
                <SfListItem
                  v-for="(sortByOption, sortByOptionIndex) in sortBy.options"
                  :key="`sortByOption-${sortByOptionIndex}`"
                >
                  <CustomRadio
                    :key="`${sortByOption.label}-${sortByOptionIndex}`"
                    :label="`${sortByOption.label
                      .replace('Sort: ', '')
                      .charAt(0)
                      .toUpperCase()}${sortByOption.label
                      .replace('Sort: ', '')
                      .slice(1)}`"
                    class="filters-sidebar__filter-checkbox"
                    :name="sortByOption.value"
                    :selected="sortBy.selected"
                    :value="sortByOption.value"
                    @change="
                      $emit('sortByClick', {
                        label: sortByOption.label,
                        value: sortByOption.value,
                      })
                    "
                  />
                </SfListItem>
              </SfList>
            </SfAccordionItem>
          </div>
          <div
            v-for="(facet, facetIndex) in cleanedFacets"
            :key="`facet-${facetIndex}`"
            class="filter-list-wrapper"
            @click="
              $emit('accordionClick', {
                name: facetsMap.get(facet.label),
                target: 'accordion',
              })
            "
          >
            <SfAccordionItem
              :header="facetsMap.get(facet.label)"
              :class="{
                'not-active':
                  !openAccordions.includes(facetsMap.get(facet.label)) &&
                  openAccordions.length > 0,
              }"
            >
              <template #additional-info="{ isOpen }">
                <ChevronBlackDown
                  v-if="!isOpen && openAccordions.length === 0"
                />
                <ChevronBlackUp v-else-if="isOpen" />
                <ChevronGrayDown v-else />
              </template>
              <SfList>
                <SfListItem
                  v-for="(facetOption, facetOptionIndex) in facet.options"
                  :key="`facetOption-${facetOptionIndex}`"
                >
                  <CustomCheckbox
                    v-if="facet.id !== 'sdc_colore_vetro'"
                    :key="`${facetOption.attrName}-${facetOption.selected}`"
                    :label="`${facetOption.attrName
                      .charAt(0)
                      .toUpperCase()}${facetOption.attrName.slice(1)}`"
                    class="filters-sidebar__filter-checkbox"
                    name="filter-checkbox"
                    info-message=""
                    error-message=""
                    :selected-value="
                      activeFilters.includes(facetOption.attrName)
                    "
                    @checkboxChange="
                      $emit('filterClick', {
                        name: facetOption.attrName,
                        target: 'filter',
                        option: {
                          facet: facet.id,
                          option: facetOption,
                        },
                      })
                    "
                  />
                  <div
                    v-else
                    class="filter-items__filter-color"
                    @click="
                      $emit('filterClick', {
                        name: facetOption.attrName,
                        target: 'filter',
                        option: {
                          facet: facet.id,
                          option: facetOption,
                        },
                      })
                    "
                  >
                    <div
                      v-if="
                        facetOption.swatch_data &&
                        facetOption.swatch_data.thumbnail
                      "
                      class="filter-items__filter-color color-filter-wrapper"
                    >
                      <div
                        class="swatch-color"
                        :class="{
                          'active-color': activeFilters.includes(
                            facetOption.attrName
                          ),
                        }"
                      >
                        <nuxt-img
                          :src="facetOption.swatch_data.thumbnail.url"
                        />
                      </div>
                      <div>
                        <span>{{ $t(facetOption.attrName) }}</span>
                      </div>
                    </div>
                    <div
                      class="color-filter-wrapper"
                      v-else-if="hexCode(facetOption)"
                    >
                      <div
                        class="color-filter__hex"
                        :class="{
                          'active-color': activeFilters.includes(
                            facetOption.attrName
                          ),
                        }"
                        :style="{ backgroundColor: hexCode(facetOption) }"
                      />
                      <span class="color-filter__label">{{
                        `${facetOption.attrName}`
                      }}</span>
                    </div>
                  </div>
                </SfListItem>
              </SfList>
            </SfAccordionItem>
          </div>
        </SfAccordion>
      </div>
    </div>
    <template #content-bottom>
      <CustomCta
        class="apply-filter-cta"
        type="button"
        @click="$emit('applyFilters')"
        >{{ $t('See results') }}</CustomCta
      >
    </template>
  </CustomSidebar>
</template>

<script>
import { SfList, SfAccordion } from '@storefront-ui/vue';
import { IconCloseBlack } from '~/components/General/Icons';
import { addBasePath } from '~/helpers/addBasePath';
import CustomCta from '~/components/General/CustomCta.vue';
import { CustomCheckbox, CustomRadio } from '~/components/General/FormElements';
import CustomSidebar from '~/components/General/CustomSidebar.vue';
import { defineComponent } from '@nuxtjs/composition-api';
import {
  ChevronBlackDown,
  ChevronBlackUp,
  ChevronGrayDown,
} from '~/components/General/Icons';
import { useUiHelpers } from '~/composables';

export default defineComponent({
  name: 'FiltersSidebar',
  methods: {
    addBasePath,
  },
  components: {
    CustomRadio,
    SfList,
    SfAccordion,
    IconCloseBlack,
    CustomCta,
    ChevronBlackDown,
    ChevronBlackUp,
    ChevronGrayDown,
    CustomCheckbox,
    CustomSidebar,
  },
  props: {
    openFilters: {
      type: Boolean,
      required: true,
    },
    productsCategoriesNames: {
      type: Object,
      default: () => {},
    },
    sortBy: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disableResetFilter: {
      type: Boolean,
      default: false,
    },
    openAccordions: {
      type: Array,
      default: () => [],
    },
    cleanedFacets: {
      type: Array,
      default: () => [],
    },
    facetsMap: {
      type: Map,
    },
    activeFilters: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const uiHelpers = useUiHelpers();
    const { filters: filtersFromUrl } = uiHelpers.getFacetsFromURL();
    const hexCode = (optionValue) => optionValue?.swatch_data?.hex_code;

    return {
      hexCode,
      props,
      filtersFromUrl,
    };
  },
});
</script>

<style lang="scss">
.filters-sidebar {
  .sf-sidebar {
    &__aside {
      z-index: 4;
    }
    &__top {
      padding: var(--space-s);

      .filters-sidebar {
        &__top {
          @include mobile-h5;
          display: flex;
          justify-content: space-between;
          padding-bottom: var(--space-s);
          &-title {
            margin: 0;
          }
          &__icon {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    &__content {
      padding: 0 1.563rem;
      height: fit-content;
      margin-bottom: var(--space-xs);
      flex-grow: 1;

      .filters-sidebar__content {
        &-active-filters {
          @include flexbox($justifyContent: space-between);
          padding-bottom: 1.428rem;
          margin-left: -0.75rem;

          .filters {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            min-height: 2rem;
            &--box {
              display: flex;
              flex-wrap: wrap;
            }
            .filters-inner-container {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 0.5rem;
              margin-bottom: 0.25rem;
              padding: 0.375rem 0.75rem;
              &:not(:last-child) {
                margin-right: 0.643rem;
              }
              &:hover {
                background-color: var(--c-super-light-grey);
                border-radius: 3px;
                cursor: pointer;
              }
              .filters {
                &-cross {
                  @include pointer;
                }
              }
            }
          }
          .delete-filters {
            @include paragraph-m;
            text-decoration: underline;
            white-space: nowrap;
            padding: 0.375rem 0;
            @include pointer;
            &__disabled {
              pointer-events: none;
              opacity: 0.3;
            }
          }
        }

        &-available-filters {
          border-top: 1px solid var(--c-grey);
          .sf-accordion {
            .filter-list-wrapper {
              margin: 0.094rem 0;
              &:last-child {
                .sf-accordion-item {
                  border: none;
                }
              }
              .sf-accordion-item {
                border-bottom: 1px solid var(--c-light-gray);
                &.not-active {
                  & > button {
                    color: var(--c-dark-gray);
                  }
                }
                &__header {
                  @include desktop-h7;
                  min-height: 54px;
                  &.is-open {
                    color: black;
                    border: none;
                  }
                }
                &__content {
                  border-bottom: 1px solid var(--c-grey);
                  .sf-list {
                    &__item {
                      padding-bottom: 1rem;
                      .filters-sidebar__filter-checkbox > div {
                        margin-left: 1px;
                      }
                      .filter-items__filter-color {
                        @include paragraph-m;
                        @include pointer;

                        .color-filter-wrapper {
                          display: flex;
                          align-items: center;
                          gap: 1rem;
                          .color-filter__hex {
                            width: 1.5rem;
                            height: 0.5rem;
                            border: 0.5px solid var(--c-black);
                            &.active-color {
                              border: 3px solid var(--c-black);
                              scale: 1.3;
                            }
                          }

                          .color-filter__label {
                            @include paragraph-m;
                          }
                        }
                        &.color-swatches {
                          position: relative;
                          width: 1.5rem;
                          height: 0.5rem;
                          overflow: hidden;
                          img {
                            width: 200%;
                            left: -50%;
                            object-fit: fill;
                            top: -50%;
                            position: relative;
                            object-position: center;
                          }
                        }
                        .swatch-color {
                          position: relative;
                          width: 1.5rem;
                          height: 0.5rem;
                          overflow: hidden;
                          border: 0.5px solid var(--c-black);

                          &.active-color {
                            border: 3px solid var(--c-black);
                            scale: 1.3;
                          }
                          img {
                            width: 200%;
                            left: -50%;
                            top: -50%;
                            position: relative;
                            object-position: center;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &__bottom {
      text-transform: uppercase;
      width: 100%;
      padding: var(--space-s) 1.563rem;

      &:has(> .hide-bottom) {
        display: none;
      }
    }
  }

  .sf-overlay {
    z-index: 4;
    --overlay-z-index: 4;
  }

  &__top-icon {
    @include pointer;
  }

  .color-tooltip {
    z-index: 2;
    .tooltip-inner {
      position: relative;
      padding: var(--spacer-xs) var(--spacer-sm);
      bottom: var(--spacer-sm);
      background: var(--c-white);
      border: var(--general-border);
      border-radius: var(--spacer-sm);
      @include label-s;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-top-color: #ffffff;
        border-bottom: 0;
        margin-left: -8px;
        margin-bottom: -8px;
        filter: drop-shadow(0 0 0 #000);
      }
    }
  }
  .apply-filter-cta {
    margin: 1.25rem;
  }
}
</style>
